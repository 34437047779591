import { appApi, createJsonAccessToken } from "./apiInstance";

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const userModule = 'user';
const talentModule = 'talent'
const feedbackModule = 'feedback'
const catalogModule = 'catalog';
const checkupModule = 'check-up'
const feedbackComments = 'comments';
const improvementActions = 'improvement-actions';
const internalNotes = 'internal-notes';

export const getFeedbackStatus = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogModule}/status-process`);

export const getFeedbacks = (userId, talentId, accessToken, page=0, size=5) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}?paged=true&page=${page}&size=${size}`);

export const getFeedback = (userId, talentId, feedbackId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}`);

export const saveFeedback = (userId, talentId, feedback, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}`, feedback);

export const updateFeedbackStatus = (userId, talentId, feedbackId, statusId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/status/${statusId}`);

export const updateFeedbackStepIndex = (userId, talentId, feedbackId, stepIndex, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).put(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/stage-index/${stepIndex}`);

export const getCheckUpDetail = (userId, talentId, feedbackId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/checkup-detail`);

export const getCheckUpQuestions = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogModule}/talent-check-up-item-types`);

export const getCheckUpFollowUp = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${catalogModule}/talent-check-up-follow-item-types`);

export const getCheckUpByTalent = (userId, talentId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}`);

export const getCheckUpByFeedback = (userId, talentId, feedbackId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}`);

export const getCheckUpById = (userId, talentId, checkupId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${checkupModule}/${checkupId}`);

export const saveCheckUp = (userId, talentId, feedbackId, checkup, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}`, checkup);

export const saveCheckUpItem = (userId, talentId, feedbackId, checkupId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/item`, request);

export const saveCheckUpFollowItem = (userId, talentId, feedbackId, checkupId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/follow-item`, request);

export const shareWithLeader = (userId, talentId, feedbackId, checkupId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/share-with-leader`);

export const sendCheckupCompletion = (userId, talentId, feedbackId, checkupId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/send-checkup-completion-notification`);

export const getComments = (userId, talentId, feedbackId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${feedbackComments}`);

export const saveComments = (userId, talentId, feedbackId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${feedbackComments}`, request);

export const getImprovements = (userId, talentId, feedbackId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${improvementActions}`);

export const saveImprovements = (userId, talentId, feedbackId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${improvementActions}`, request);

export const getInternalNotes = (userId, talentId, feedbackId, checkupId, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/${internalNotes}`);

export const saveInternalNotes = (userId, talentId, feedbackId, checkupId, request, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${userModule}/${userId}/${talentModule}/${talentId}/${feedbackModule}/${feedbackId}/${checkupModule}/${checkupId}/${internalNotes}`, request);