import {
    saveCheckUp,
    saveCheckUpFollowItem,
    saveCheckUpItem,
    saveComments,
    saveImprovements,
    saveInternalNotes,
    sendCheckupCompletion,
    shareWithLeader,
    updateFeedbackStatus,
    updateFeedbackStepIndex
} from "../../../api/feedbackClient";
import { saveWorkEnv, updateWorkEnv } from "../../../api/talentInfoClient";
import { loadFollowUp } from "./loadThunks";

const persistComments = (context, checkupId) => {
    if( context.checkup.comments ) {
        context.checkup.comments.forEach(c => {
            if( c.value && c.value.comment && c.value.comment.length > 0 ) {
                c.satisfactionLevelId = c.value.id;
                c.comments = c.value.comment;
                c.talentCheckUpItemTypeId = c.id;
                saveCheckUpItem(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, checkupId, c, context.appContext.userData.token).then(response => {
                    // Comment saved
                }).catch(error => {
                    console.log(error);
                })
            }
        })
    }
    if( context.checkup.followUps ) {
        context.checkup.followUps.forEach(c => {
            if( c.value && c.value.comment && c.value.comment.length > 0 ) {
                c.satisfactionLevelId = c.value.id;
                c.comments = c.value.comment;
                c.talentCheckUpFollowItemTypeId = c.id;
                saveCheckUpFollowItem(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, checkupId, c, context.appContext.userData.token).then(response => {
                    // Follow saved
                    context.setHasFollowItems(true);
                }).catch(error => {
                    console.log(error);
                })
            }
        })
    }
}

const persistWorkEnv = (context, checkupId) => {
    const request = {
        classificationId: context.checkup.clasification.id,
        exitRisk: context.checkup.existRisk.id,
        createdBy: context.appContext.userData.userProfile.id,
        updatedBy: context.appContext.userData.userProfile.id
    }
    if( context.checkup.workEnviroimentId ) {
        updateWorkEnv(context.appContext.userData.userProfile.id, context.appContext.talent.id, checkupId, request, context.appContext.userData.token).then(response => {
            context.appContext.contentScrollUp();
            context.appContext.displayNotification((context.appContext.t('update')));
        }).catch(error => {
            console.log(error);
        })
    } else {
        saveWorkEnv(context.appContext.userData.userProfile.id, context.appContext.talent.id, checkupId, request, context.appContext.userData.token).then(response => {
            // Work enviroiment saved
        }).catch(error => {
            console.log(error);
        })
    }
}

export const onSaveClimate = context => {
    const checkup = {
        ...context.checkup,
        id: context.checkup.id,
        position: 'Programador',
        assignedProjects: 'Muchos Proyectos'
    }
    saveCheckUp(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, checkup, context.appContext.userData.token).then(({status, data}) => {
        if( status === 200 && data.code === 202 ) {
            const { result } = data;
            if (!context.checkup.id /*&& context.tap === 1 FIX Bug ES-6722 */) {
                persistWorkEnv(context, result.id);
                const checkUpNew = {
                    ...context.checkup,
                    id: result.id,
                    createdBy: result.createdBy,
                    processStatus: result.processStatus
                };
                persistComments(context, result.id);
                loadFollowUp(checkUpNew, context);
                if( !context.modeBack ) {
                    context.changeTap(3, true);
                }
                onUpdateStepIndex(context, 3)
            } else {
                persistWorkEnv(context, result.id);
                persistComments(context, result.id);
                if ( context.tap === 1 ) {
                    if( !context.modeBack ) {
                        context.changeTap(0);
                    }
                } else if (context.tap === 0) {
                    if( !context.modeBack ) {
                        context.changeTap(context.modeUdpate ? 6 : 3, true);
                    }
                    if( !context.modeUdpate ) {
                        onUpdateStepIndex(context, 3)
                    }
                }
            }
            context.appContext.contentScrollUp();
            context.appContext.displayNotification(context.appContext.t('update'));
        } else if( status === 202 && data.code === 400 ) {
            context.appContext.displayNotification(data.msg);
        }
    }).catch(error => {
        console.log('Error to save climate', error);
    })
}

const isGoToImproveSection = (review, changeTap, context) => {
    const isThereNoOpportunities = !review.opportunities || review.opportunities.length === 0;
    const tapForward = isThereNoOpportunities ? 5 : 4;
    if( !context.modeBack ) {
        changeTap(tapForward, true);
    }
    if(context) {
        onUpdateStepIndex(context, tapForward);
    }
}

export const onSaveComments = context => {
    if( context.checkup.feedbackComments.length > 0 ) {
        const requestArray = []
        context.checkup.feedbackComments.forEach(c => requestArray.push(saveComments(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, c, context.appContext.userData.token)))
        Promise.all(requestArray).then( responses => {
            responses.forEach((response, index) => {
                if(index === requestArray.length - 1) {
                    context.setCheckup(context.checkup);
                    context.setCheckupCache(JSON.stringify(context.checkup));
                    context.appContext.displayNotification((context.appContext.t('update')));
                    isGoToImproveSection(context.review, context.changeTap, context);
                }
            })
        }).catch( error => {
            console.log(error);
        });
    } else {
        isGoToImproveSection(context.review, context.changeTap);
    }
}

export const onSaveImprovementActions = context => {
    const requestArray = [];
    context.checkup.improvements.forEach(c => requestArray.push(saveImprovements(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, c, context.appContext.userData.token)))
    Promise.all(requestArray).then( responses => {
        responses.forEach((response, index) => {
            if(index === requestArray.length - 1) {
                context.setCheckup(context.checkup);
                context.setCheckupCache(JSON.stringify(context.checkup));
                context.appContext.displayNotification((context.appContext.t('update')));
                if( !context.modeBack ) {
                    context.changeTap(context.tap+1, true);
                }
                onUpdateStepIndex(context, context.tap+1);
            }
        })
    }).catch( error => {
        console.log(error);
    });
}

export const onSaveInternalNotes = context => {
    const requestArray = [];
    context.checkup.internalNotes.forEach(i => {
        if( (i.isNew && i.comments.length > 0) || !i.isNew ) {
            requestArray.push(saveInternalNotes(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.checkup.id, i, context.appContext.userData.token))
        }
    })
    const updateStatus = context.review.processStatus.id === 1000530001;
    if( updateStatus && !context.modeUpdate ) {
        requestArray.push(sendCheckupCompletion(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.checkup.id, context.appContext.userData.token))
        const followDoneStatus = 1000530002;
        requestArray.push(updateFeedbackStatus(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, followDoneStatus, context.appContext.userData.token));
    }
    context.appContext.showLoading(true, context.appContext.t('loading'), context.style);
    Promise.all(requestArray).then(responses => {
        responses.forEach((response, index) => {
            if(index === requestArray.length - 1) {
                context.setCheckup(context.checkup);
                context.setCheckupCache(JSON.stringify(context.checkup));
                context.appContext.showLoading(false, '', context.style);
                context.changeTap(context.tap+1, true);
                if( updateStatus && !context.modeUpdate ) {
                    onUpdateStepIndex(context, context.tap+1);
                    context.appContext.displayNotification(context.appContext.t('checkUp.msgFinish'));
                    handleUpdateStatus(context, response);
                    if( !context.modeUpdate ) {
                        context.setModeUpdate(true);
                    }
                } else {
                    context.appContext.displayNotification((context.appContext.t('update')));
                }
            }
        })
    }).catch( error => {
        console.log('Error saving internal notes', error);
        context.appContext.showLoading(false, '', context.style);
    });
}

export const onUpdateStatus = context => {
    context.appContext.showLoading(true, context.appContext.t('loading'), context.style);
    shareWithLeader(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.checkup.id, context.appContext.userData.token).then(response => {
        context.appContext.showLoading(false, '', context.style);
        if( response.status === 200 ) {
            updateFeedbackStatus(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.statusId, context.appContext.userData.token).then(response => {
                handleUpdateStatus(context, response);
                if( response.status === 200 && response.data.code === 202 ) {
                    context.appContext.displayNotification(context.appContext.t(context.message));
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }).catch(error => {
        console.log('Error update status', error);
        context.appContext.showLoading(false, '', context.style);
    })
}

const handleUpdateStatus = (context, response) => {
    const { status, data } = response;
    if( status === 200 && data.code === 202 ) {
        // Update status in review
        context.setReview({ ...context.review, 'processStatus': data.result.processStatus });
        // Update status in review list
        const reviewsTmp = [ ...context.reviews ];
        const reviewIndex = reviewsTmp.findIndex( r => r.id === context.review.id );
        reviewsTmp[reviewIndex].processStatus = data.result.processStatus;
        context.setReviews(reviewsTmp);
    }
}

export const onUpdateStepIndex = (context, stepIndex) => {
    updateFeedbackStepIndex(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, stepIndex, context.appContext.userData.token).then(response => {
        // Update Feedback Saved
    }).catch(error => {
        console.log('Error update status', error);
    })
}