import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppProvider";
import styles from '../../candidateInfo.module.scss';
import addIcon from '../../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import { CandidateContext } from "../../context";
import Select, { SelectSortType } from "../../../../components/inputs/Select";
import { validateIsSelected } from "../../../../core/validators";
import { getViewers } from "../../../../api/talentInfoClient";
import { selectEmtpy } from "../../../../core/hardcode";

export const validateVisibilityRequest = visibilities => {
    const errorObject = {};
    if( visibilities.length > 0 ) {
        visibilities.forEach( (v, index) => {
            const resultValidation = validateIsSelected(v.visibility);
            if( resultValidation ) {
                errorObject[`visibility_${index}`] = resultValidation
            }
        })
    }
    return errorObject;
}

export const Visibility = ({
    resumeViewFlag,
    renderBackButton,
    catUser
}) => {

    const appContext = useContext(AppContext);
    const {
        visibility,
        cleanVisibility,
        visibilities,
        setVisibilities,
        setVisibilitiesCache,
        visibilityErrors,
        setVisibilityErrors
    } = useContext( CandidateContext );

    const [optionsSelecteds, setOptionsSelecteds] = useState([]);
    const [usersNoViwers, setUsersNoViwers] = useState([]);

    const getUserViewers = () => {
        getViewers(appContext.talent.id, appContext.userData.token).then(({status, data}) => {
            if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
                const visibilitiesTmp = [];
                const usersNoViwers = [];
                data.result.forEach( r => {
                    const visibilityFind = catUser.find( c => c.id === r.id );
                    if( visibilityFind ) {
                        visibilitiesTmp.push({
                            visibility: { id: visibilityFind.id, description: visibilityFind.optionName },
                            visibilityId: visibilityFind.id
                        });
                    } else {
                        usersNoViwers.push(`${r.firstName} ${r.lastName}`);
                    }
                })
                setUsersNoViwers(usersNoViwers);
                setVisibilities(visibilitiesTmp);
                setVisibilitiesCache(visibilitiesTmp);
            }
        }).catch( error => {
        })
    }

    useEffect(() => {
        getUserViewers();
    }, [])

    const onClickAdd = () => {
        const errorValidations = validateVisibilityRequest(visibilities);
        setVisibilityErrors(errorValidations);
        if (!(Object.keys(errorValidations).filter( w => w.includes('visibility_')).length > 0) && visibilities.length < catUser.length) {
            const index = visibilities.length;
            cleanVisibility();
            const tmpVisibility = {
                ...visibility,
                'index': index,
            }
            setVisibilities([...visibilities, tmpVisibility]);
        }
    }

    const updateVisibility = (index, property, value, preValue) => {
        const indexReal = index - 1;
        const propertyReal = property.replace(`_${indexReal}`, '');
        const visibility = {
            ...visibilities.find( l => l.index === indexReal ),
            [propertyReal]: value && value.id ? value : { id: 0, description: value.description },
            [`${propertyReal}Id`]: value.id ? value.id : 0
        }
        if (propertyReal === 'visibility' && value && value.id && value.id !== 0) {
            const optSelectedTmp = optionsSelecteds.filter( o => o !== preValue.id );
            setOptionsSelecteds([...optSelectedTmp, value.id])
        }
        let visibilitiesTmp = [ ...visibilities ];
        visibilitiesTmp[indexReal] = visibility;
        setVisibilities(visibilitiesTmp);
        setVisibilityErrors(validateVisibilityRequest(visibilitiesTmp));
    }

    const removedFromIcon = (id) => {
        const indexHelper = optionsSelecteds.indexOf(id)
        setOptionsSelecteds([...optionsSelecteds.slice(0, indexHelper), ...optionsSelecteds.slice(indexHelper + 1)])
    }

    const handleOnClickRemove = index => {
        setOptionsSelecteds([...optionsSelecteds.slice(0, index), ...optionsSelecteds.slice(index + 1)])
        const visibilitiesTmp = [ ...visibilities ];
        visibilitiesTmp.splice(index, 1);
        setVisibilities(visibilitiesTmp);
        setVisibilityErrors(validateVisibilityRequest(visibilitiesTmp));
    }

    const renderUsersNoViwers = () => usersNoViwers.length > 0 && (
        <div>
            <p className={ styles.error_message_font }>{ appContext.t('candidate.resume.visibility.invalidRol.msg1') }</p>
            <p className={ styles.error_message_font }>{ appContext.t('candidate.resume.visibility.invalidRol.msg2') }</p>
            <ul>
                {
                    usersNoViwers.map(u => (
                        <li className={ styles.error_message_font }>{ u }</li>
                    ))
                }
            </ul>
        </div>
    )

    const renderTitles = index => index === 0 && visibilities && visibilities.length > 0 && (
        <div className={ styles.form_columns_line }>
            <div className={ styles.form_column }>
                <label>{ appContext.t('candidate.resume.visibility.titleUser') }</label>
            </div>
        </div>
    )

    const renderVisibilites = () => (
        visibilities && visibilities.length > 0 && visibilities.map((v, index) => {
            return (
                <div key={index}>
                    { renderTitles( index) }
                    <div className={ styles.form_columns_line }>
                        <div className={ styles.form_column }>
                            <div className={ styles.form_column_line }>
                                <Select
                                    name={`visibility_${index}`}
                                    placeholder={ appContext.t('candidate.resume.assignment.placeholderLeader') }
                                    value={ v.visibility || selectEmtpy }
                                    sercheable
                                    onChange={ updateVisibility }
                                    options={ catUser ? catUser : [] }
                                    optionsSelecteds={ optionsSelecteds }
                                    removedFromIcon={ removedFromIcon }
                                    index={ index+1 }
                                    error= { Object.keys(visibilityErrors).includes(`visibility_${index}`) ? visibilityErrors[`visibility_${index}`][0] : '' }
                                    sort={ SelectSortType.ALPHA }
                                />
                                <div className={ styles.action_container }>
                                    <img className={ styles.trash } src={ trashIcon } alt="" width={ 24 } height={ 24 } onClick={ () => handleOnClickRemove(index) } />
                                </div>
                            </div>
                            {
                                Object.keys(visibilityErrors).includes(`visibility_${index}`) && (
                                    <p className={ styles.error_message_font }>{ visibilityErrors[`visibility_${index}`][0] }</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            )}
        )
    )

    const renderTitle = () => (<p className={ styles.title }>{ appContext.t('candidate.resume.visibility.title') }</p>);

    return (
        <div className={ styles.form_container }>
            { appContext.isDesktopOrLaptop ? renderTitle() : ( resumeViewFlag ? renderTitle() : renderBackButton(appContext.t('candidate.resume.visibility.title'))) }
            <div className={ styles.form_wrapper }>
                <div className={ `${styles.form_row} ${styles.paragraph}` }>
                    <label className={ styles.subtitle }>{ appContext.t('candidate.resume.visibility.labelInst') }</label>
                </div>
                { renderUsersNoViwers() }
                { renderVisibilites() }
                <div className={ styles.form_row }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd() }>
                        { appContext.t('candidate.resume.visibility.labelAdd') }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        </div>
    )
}