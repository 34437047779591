import { appApi, createJsonAccessToken } from './apiInstance';

const apiUrl = process.env.REACT_APP_GATEWAY_URL;
const mainContext = 'catalog';
const skillModule = 'skills';
const candidateModule = 'candidates';
const languageModule = 'language';
const profileModule = 'profile';
const vacancyModule = 'vacancies';

export const getCatalog = (catalogId, page ,size, sort, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${catalogId}/catalogItem?page=${page}&size=${size}${sort}`);

export const getSkillsCatalog = (page ,size, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${skillModule}?page=${page}&size=${size}&sort=name&direction=ASC&isPublic=true`);

export const getSkillsCandidateCatalog = (page ,size, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${skillModule}/${candidateModule}?page=${page}&size=${size}&sort=name&direction=ASC`);

export const getSkillsVacancyCatalog = (page ,size, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${skillModule}/${vacancyModule}?page=${page}&size=${size}&sort=name&direction=ASC`);

//export const getLanguageCatalog = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${languageModule}`);
 
export const getLangLevelCatalog = accessToken => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${languageModule}/levels`);

export const getClientCatalog = (accessToken, sort) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/client?sortBy=${ sort ? sort : 'name' }`);

export const getAreaCatalog = (accessToken, sort) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/area?sortBy=${ sort ? sort : 'name' }`);

export const getAddressesCatalog = (accessToken, sort) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/addresses`);

export const getProfiles = (filter, page=0, size=10, clientFilter, visibilityFilter, accessToken) => {
    const filterParam = filter ? `&name=${filter}` : '';
    let clientParam = '';
    if( clientFilter ) {
        clientFilter.forEach( c => clientParam += `&clientId=${c.id}` )
    }
    const publicParam = visibilityFilter.length === 1 ? `&isPublic=${ visibilityFilter.find( v => v.id === 1) ? 'true' : 'false' }` : '';
    const sorter = '&sortBy=name&sortDir=ASC';
    return appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${skillModule}/${profileModule}?paginated=true&page=${page}&size=${size}${sorter}${filterParam}${clientParam}${publicParam}`);
}

export const getProfile = (id, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).get(`${mainContext}/${skillModule}/${profileModule}/${id}/info`);

export const persistProfile = (profile, accessToken) => appApi(apiUrl, createJsonAccessToken(accessToken)).post(`${mainContext}/${skillModule}/${profileModule}`, profile);