import { types } from "./types";

export const catalogReducer = ( state = {}, action ) => {
    switch ( action.type ) {
        case types.setProfile:
            return {
                ...state,
                profile: action.payload
            }
        case types.setProfileCache:
            return {
                ...state,
                profileCache: action.payload
            }
        case types.setVacancy:
            return {
                ...state,
                vacancy: action.payload
            }
        case types.setVacancyCache:
            return {
                ...state,
                vacancyCache: action.payload
            }
        default:
            return state;
    }
}