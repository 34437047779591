import {
    getCheckUpByFeedback,
    getCheckUpDetail,
    getCheckUpFollowUp,
    getCheckUpQuestions,
    getComments,
    getImprovements,
    getInternalNotes
} from "../../../api/feedbackClient";
import { getWorkEnv } from "../../../api/talentInfoClient";
import { getUser } from "../../../api/userClient";

const finishLoading = (context, checkup) => {
    context.setCheckup(checkup);
    context.setCheckupCache(JSON.stringify(checkup));
    context.appContext.showLoading(false, '', context.style);
}

const loadCreator = (checkup, context) => {
    // console.log('Loading creator')
    if( checkup.createdBy ) {
        getUser(context.appContext.userData.token, checkup.createdBy).then(({status, data}) => {
            if( status === 200 && data.code === 202 && data.result ) {
                const { firstName, lastName } = data.result;
                const newCheckup = {
                    ...checkup,
                    creator: `${firstName} ${lastName}`
                }
                finishLoading(context, newCheckup);
            } else {
                finishLoading(context, checkup);
            }
        }).catch(error => {
            console.log('Error loading creator', error)
            finishLoading(context, checkup);
        })
    } else {
        finishLoading(context, checkup);
    }
}

const getCommentsInit = (comments, typeId, items = []) => {
    comments.forEach( c => {
        const itemFinded = items.find( i => i[typeId] === c.id )
        c.value = itemFinded ? {
            id: itemFinded.satisfactionLevelId,
            comment: itemFinded.comments
        } : { id: 1000570003, comment: '' };
    })
    return comments;
}

export const loadFollowUp = (checkup, context, items) => {
    getCheckUpFollowUp(context.appContext.userData.token).then(({status, data}) => {
        if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
            checkup.followUps = getCommentsInit(data.result.filter(c => c.isActive), 'talentCheckUpFollowItemTypeId', items);
            loadCreator(checkup, context);
        } else {
            finishLoading(context, checkup);
        }
    }).catch( error => {
        console.log('Error loading follo ups', error);
        finishLoading(context, checkup);
    });
}

const getNotesInit = (internalNotes, catInternalNotes) => {
    const notes = []
    catInternalNotes.forEach(c => {
        const internalNote = internalNotes ? internalNotes.find(i => i.noteTypeId === c.id) : null
        notes.push({
            noteTypeId: internalNote ? internalNote.noteTypeId : c.id,
            noteTypeTitle: c.optionName,
            comments: internalNote ? internalNote.comments : '',
            isNew: internalNote ? false : true
        })
    })
    return notes;
}

const loadInternalNotes = (checkup, comments, catInternalNotes, context) => {
    // console.log('Loading internal notes')
    if( checkup && checkup.id ) {
        getInternalNotes(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, checkup.id, context.appContext.userData.token).then(({status, data}) => {
            if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
                checkup.internalNotes = getNotesInit(data.result, catInternalNotes);
            } else {
                checkup.internalNotes = getNotesInit(null, catInternalNotes);
            }
            loadFollowUp(checkup, context, comments);
        }).catch(error => {
            console.log('Error loading internal notes', error);
            checkup.internalNotes = getNotesInit(null, catInternalNotes);
            finishLoading(context, checkup);
        })
    } else {
        checkup.internalNotes = getNotesInit(null, catInternalNotes);
        loadFollowUp(checkup, context, comments);
    }
}

const buildImprovement = (skill, comment, context) => {
    return {
        feedbackId: comment ? comment.feedbackId : context.review.id,
        skillTagId: skill.tagId,
        comments: comment ? comment.comments: '',
        createdBy: comment ? comment.createdBy : context.appContext.userData.userProfile.id,
        name: skill.name,
        categoryId: skill.categoryId
    }
}

const getImprovementInit = (comments, context) =>  {
    const improvements = [];
    context.review.opportunities.forEach(o => {
        const comment = comments ? comments.find(c => c.skillTagId === o.tagId) : null;
        improvements.push(buildImprovement(o, comment, context));
    })
    return improvements;
}

const loadImprovementActions = (checkup, followItems, context, catalogs) => {
    // console.log('Loading improvement actions')
    getImprovements(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token).then(({status, data}) => {
        if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
            checkup.improvements = getImprovementInit(data.result, context);
        } else {
            checkup.improvements = getImprovementInit(null, context);
        }
        //loadCheckup(checkup, comments, context, catalogs);
        loadInternalNotes(checkup, followItems, catalogs[3], context);
    }).catch(error => {
        console.log('Error loading improvement actions', error);
        checkup.improvements = getImprovementInit(null, context);
        finishLoading(context, checkup);
    })
}

const loadFeedbackComments = (checkup, followItems, context, catalogs) => {
    // console.log('Loading feedback')
    checkup.feedbackComments = []
    getComments(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token).then(({status, data}) => {
        // console.log('loading feedback', status, data.code);
        if( status === 200 && data.code === 202 && Array.isArray(data.result) && data.result.length > 0 ) {
            checkup.feedbackComments = data.result;
        } else {
            checkup.feedbackComments = [
                { typeId: 1, comments: '' },
                { typeId: 2, comments: '' }
            ]
        }
        loadImprovementActions(checkup, followItems, context, catalogs);
    }).catch(error => {
        console.log('Error loading feedback comments', error);
        finishLoading(context, checkup);
    })
}

const loadWorkEnviroiment = (checkup, followItems, context, catalogs, preCheckupId) => {
    // console.log('Loading work enviroiment')
    const newCheckup = { ...checkup }
    getWorkEnv(context.appContext.userData.userProfile.id, context.appContext.talent.id, preCheckupId || checkup.id, context.appContext.userData.token).then(({status, data}) => {
        // console.log('loading work env', status, data.code)
        if(status === 200 && data.code === 202) {
            newCheckup.workEnviroimentId = data.result.talentId;
            newCheckup.clasification = catalogs[0].find( c => c.id === data.result.classificationId );
            newCheckup.existRisk = catalogs[1].find( c => c.id === data.result.exitRisk );
        }
        // loadQuestions(newCheckup, context, catalogs);
        loadFeedbackComments(newCheckup, followItems, context, catalogs);
    }).catch(error => {
        // loadQuestions(newCheckup, context, catalogs);
        loadFeedbackComments(newCheckup, followItems, context, catalogs);
        console.log('Error loading work env', error);
    })
}

const loadCheckup = (checkup, comments, context, catalogs) => {
    // console.log('Loading checkup')
    // getCheckUpByTalent(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.appContext.userData.token).then(({status, data}) => {
    getCheckUpByFeedback(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token).then(({status, data}) => {
        // const result = data.result[0];
        // console.log('loading checkup', status, data.code)
        const { result } = data;
        let checkUpTmp = {
            ...checkup,
            comments: getCommentsInit(comments.filter(c => c.isActive))
        };
        let followItems = [];
        if( status === 200 && data.code === 202 && result && result.checkUp) {
            checkUpTmp = {
                ...checkup,
                ...result.checkUp,
                comments: getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId', result.items)
            }
            followItems = result.followItems;
            loadWorkEnviroiment(checkUpTmp, followItems, context, catalogs);
        } else if( status === 202 && data.code === 404 && !result) {
            getCheckUpByFeedback(context.appContext.userData.userProfile.id, context.appContext.talent.id, 0, context.appContext.userData.token).then(({status, data}) => {
                if( status === 200 && data.code === 202 && data.result && data.result.checkUp) {
                    checkUpTmp.comments = getCommentsInit(comments.filter(c => c.isActive), 'talentCheckUpItemTypeId', data.result.items)
                    loadWorkEnviroiment(checkUpTmp, followItems, context, catalogs, data.result.checkUp.id);
                } else {
                    loadFeedbackComments(checkUpTmp, followItems, context, catalogs);
                }
            }).catch(error => {
                console.log('Error loading checkup', error);
                finishLoading(context, checkUpTmp);
            })
        } else {
            finishLoading(context, checkUpTmp);
        }
        //loadInternalNotes(checkUpTmp, followItems, catalogs[3], context);
    }).catch(error => {
        console.log('Error loading checkup', error);
        finishLoading(context, checkup);
    })
}

const loadQuestions = (context, catalogs) => {
    // console.log('Loading questions')
    const checkup = {
        clasification: catalogs[0].find( c => c.id === 1000580001 ),
        existRisk: catalogs[1].find( c => c.id === 1000560001 )
    }
    getCheckUpQuestions(context.appContext.userData.token).then(({status, data}) => {
        if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
            //loadFeedbackComments(checkup, data.result, context, catalogs);
            loadCheckup(checkup, data.result, context, catalogs);
        } else {
            finishLoading(context, checkup);
        }
    }).catch( error => {
        console.log('Error loading questions cat', error);
        finishLoading(context, checkup);
    })
}

const loadCheckupDetail = context => {
    getCheckUpDetail(context.appContext.userData.userProfile.id, context.appContext.talent.id, context.review.id, context.appContext.userData.token)
    .then(({status, data}) => {
        if(status === 200 && data.code === 202) {
            context.setHasFollowItems(data.result.followItems && data.result.followItems === 'CAPTURADO');
        }
    })
    .catch(error => {
        console.log('Error to load checkup detail')
    })
}

export const executeMultiPromises = context => {
    context.appContext.showLoading(true, context.appContext.t('loading'), context.style);
    Promise.all(context.requestArray).then(responses => {
        const catalogs = [];
        responses.forEach(({status, data}, index) => {
            if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
                let catTmp = [];
                data.result.content.map( l => {
                    const type = { id: l.catalogItemId, optionName: l.valueItem, description: l.description }
                    catTmp.push(type);
                })
                catalogs.push(catTmp);
                if(context.functions[index]) {
                    context.functions[index](catTmp);
                }
            }
        })
        if(catalogs.length === context.functions.length) {
            // loadWorkEnviroiment(context, catalogs);
            loadQuestions(context, catalogs);
            loadCheckupDetail(context);
        }
    }).catch( error => {
        console.log('Error loading catalogs', error);
        context.appContext.showLoading(false, '', context.style);
    });
}