import { useReducer } from "react"
import { CatalogContext } from "./CatalogContext"
import { catalogReducer } from "./catalogReducer"
import { selectEmtpy } from "../../../core/hardcode"
import { types } from "./types"

const profileEmpty = {
    id: 0,
    name: '',
    client: selectEmtpy,
    isPublic: true,
    skills: []
}

const vacancyEmpty = {
    id: 0,
}

const init = () => {
    return {
        profile: profileEmpty,
        profileCache: profileEmpty,
        vacancy: vacancyEmpty,
        vacancyCache: vacancyEmpty
    }
}

export const CatalogProvider = ({ children }) => {

    const [catalogState, dispatch] = useReducer(catalogReducer, {}, init)

    const setProfile = ( profile = {} ) => {
        dispatch({ type: types.setProfile, payload: profile })
    }

    const setProfileCache = ( profile = {} ) => {
        dispatch({ type: types.setProfileCache, payload: profile })
    }

    const cleanProfile = ( ) => {
        dispatch({ type: types.setProfile, payload: profileEmpty });
        dispatch({ type: types.setProfileCache, payload: profileEmpty });
    }

    const setVacancy = ( vacancy = {} ) => {
        dispatch({ type: types.setVacancy, payload: vacancy })
    }

    const setVacancyCache = ( vacancy = {} ) => {
        dispatch({ type: types.setVacancyCache, payload: vacancy })
    }

    return (
        <CatalogContext.Provider value={{
            ...catalogState,
            setProfile,
            setProfileCache,
            cleanProfile,
            setVacancy,
            setVacancyCache
        }}>
            { children }
        </CatalogContext.Provider>
    )
}