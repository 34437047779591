import { useContext } from 'react';
import styles from './candidateInfo.module.scss';
import { AppContext } from './context/AppProvider';
import { InfoMessage, InfoMessageType } from '../talents/components/misc/InfoMessage';
import { BarRequestStatus } from '../../components/inputs/BarRequestStatus';

export const CandidatePostulation = ({
    postulations
}) => {

    const appContext = useContext(AppContext);

    const renderNoFound = () => {
        const title = appContext.t('candidate.resume.postulation.notFoundTitle');
        const subtitles = [`${appContext.talent.firstName} ${appContext.t('candidate.resume.postulation.notFoundDetail')}`];
        const cssParagraph = styles.pharagrap_simple;
        const type = InfoMessageType.notFound;
        return <InfoMessage title={ title } subtitles={ subtitles } type={ type } cssParagraph={ cssParagraph } />
    }

    const renderPoslutates = () => postulations.map((p, index) => (
        <div key={ index }>
            <div className={ styles.section_main }>
                <div className={ styles.section_header}>
                    <div className={ styles.section_title }>
                        <div className={styles.decorator} />
                    </div>
                    <label className={ styles.section_title }>{ `${p.id}: ${p.vacancyName}` }</label>
                    <div className={ styles.vertical_separator } />
                    { p.recruitmentDesc }
                    <BarRequestStatus status={p.recruitment} horizontal />
                    <div className={ styles.vertical_separator } />
                    { p.client }
                    <div className={ styles.vertical_separator } />
                    { `${appContext.t('candidate.resume.postulation.recruiter')}: ${p.recruiter}` }
                </div>
            </div>
            <div className={ styles.section_header }>
                <label className={ styles.label_detail }>{appContext.t('candidate.resume.postulation.status')}: </label>{p.status}
            </div>
            <div className={ styles.section_header }>
                <label className={ styles.label_detail }>{appContext.t('candidate.resume.postulation.date')}: </label>{p.date}
            </div>
        </div>
    ))

    return (
        <div className={ styles.postulates_content }>
            { postulations && postulations.length > 0 ? renderPoslutates() : renderNoFound() }
        </div>
    )
}
