import styles from '../../checkup.module.scss';
import stylesC from '../../../candidates/candidateInfo.module.scss';
import { TextArea } from '../../../../components/inputs/TextArea';
import editIcon from '../../../../../assets/icons/orange_pencil.svg';
import { validateNotNull } from '../../../../core/validators';

export const validateImprovActions = checkup => {
    const improvToken = 'improvement';
    const checkupErrors = {}
    let firstError = false;
    checkup.improvements.forEach( c => {
        const commentValidation = validateNotNull(c.comments);
        if( commentValidation ) {
            checkupErrors[`${improvToken}_${c.skillTagId}`] = commentValidation;
            if(!firstError) {
                checkupErrors[`${improvToken}_${c.skillTagId}_focus`] = true;
                firstError = true;
            }
        }
    });
    return checkupErrors;
}

export const ImprovementActions = ({
    appContext,
    checkup,
    checkupErrors,
    onChangeInput,
    modeResume,
    changeTap,
    modeRead,
    setModeRead
}) => {

    const onChangeComment = ( id, value ) => {
        const commentsTmp = [ ...checkup.improvements ];
        const index = commentsTmp.findIndex( c => c.skillTagId === id );
        commentsTmp[index] = {
            ...commentsTmp[index],
            comments: value
        }
        onChangeInput('improvements', commentsTmp);
    }

    const onClickEdit = () => {
        changeTap(4);
        setModeRead(false);
    }

    const renderItems = () => checkup.improvements.map((i, index) => (
        <div key={index} className={ styles.row }>
            <div className={ `${styles.row_backgray} ${styles.column_short} ${styles.short}` }>
                <span className={ styles.label }>{ i.name }</span>
            </div>
            <div className={ `${styles.column_detail} ${styles.comment}` }>
                <div className={styles.section}>
                    <div className={ styles.comment }>
                        <TextArea
                            cssCounter={ styles.subtitle_left }
                            name={ i.skillTagId }
                            label={''}
                            sublabel={ '' }
                            maxLength={ 3000 }
                            errorLen={ 'validator.length' }
                            value={ i.comments }
                            onChange={ onChangeComment }
                            error={ Object.keys(checkupErrors).includes(`improvement_${i.skillTagId}`) ? checkupErrors[`improvement_${i.skillTagId}`][0] : '' }
                            focus={ Object.keys(checkupErrors).includes(`improvement_${i.skillTagId}_focus`) }
                        />
                    </div>
                </div>
            </div>
        </div>
    ))

    const renderItemsResume = () => checkup.improvements.map((i, index) => (
        <div key={index} className={ `${styles.row} ${styles.row_backgray}` }>
            <div className={ styles.column_main }>
                <span className={ styles.label }>{ i.name }</span>
            </div>
            <div className={ `${styles.column_detail} ${styles.comment}` }>{ i.comments }</div>
        </div>
    ))

    const renderTable = () => (
        <div className={ styles.table }>
            {
                modeResume && (!checkup.improvements || checkup.improvements.length === 0 ) ? undefined :
                (
                    <div className={ `${styles.row} ${styles.header}` }>
                        <div className={ styles.column_short }>{ appContext.t('checkUp.improvement.opportunity') }</div>
                        <div className={ styles.column_detail }>{ appContext.t('checkUp.improvement.title') }{ !modeResume ? '*' : ''}</div>
                    </div>
                )
            }
            <div className={ `${styles.detail} ${modeResume ? styles.gap16 : styles.gap8}` }>
                { modeRead ? renderItemsResume() : renderItems() }
            </div>
        </div>
    )

    const renderMainRow = () => (
        <div className={ stylesC.section_header}>
            <div className={ stylesC.section_title }>
                <div className={ stylesC.decorator }></div>
                { appContext.t('checkUp.improvement.title') }
            </div>
            {
                modeRead &&
                <div className={ stylesC.button_container }>
                    <div className={ stylesC.vertical_separator }></div>
                    <button className={ stylesC.add_info } onClick={onClickEdit}>
                        { appContext.t('candidate.resume.edit') }
                        <img src={ editIcon } alt="" />
                    </button>
                </div>
            }
        </div>
    )

    return (
        <div className={ `${stylesC.form_container} ${styles.checkup}` }>
            { !modeResume && <p className={ stylesC.title }>{ appContext.t('candidate.resume.review.checkUpAdd') }</p> }
            { modeResume && (!checkup.improvements || checkup.improvements.length === 0 ) ? undefined : renderMainRow() }
            { !modeResume && appContext.t('checkUp.improvement.subtitle') }
            { checkup.improvements && renderTable() }
        </div>
    )
}
