import { useContext, useState } from 'react';
import styles from './menu.module.scss';
import { ReactComponent as ArrowIcon } from '../../../assets/icons/arrow_down.svg';
import {ReactComponent as ArrowMenuIcon } from '../../../assets/icons/arrow_menu.svg';
import { AppContext } from '../../pages/candidates/context/AppProvider';
import { getOptions } from './menuOptions';

export const Menu = () => {

    const appContext = useContext(AppContext);

    const [optSelected, setOptSelected] = useState([1, 2, 3]);

    const onClickItem = objMenu => {
        appContext.cleanFilter();
        appContext.setMenuOptSelected(objMenu.title);
        appContext.setMenuNavigation([{label: objMenu.title, component: objMenu.component }]);
        appContext.setHomeContent(objMenu.component);
        appContext.setFeedback(null);
        if( objMenu.onEvent ) {
            if( objMenu.arg ) {
                objMenu.onEvent(objMenu.arg);
            } else {
                objMenu.onEvent();
            }
        }
    }

    const renderItems = items => items && items.map((i, index) => (
        <div key={ index } className={ `${styles.item} ${ appContext.menuOptSelected === i.title ? styles.selected : '' }` } onClick={ () => onClickItem(i) }>
            <span>{ i.title }</span>
        </div>
    ));

    const onClickOption = (index, option) => {
        if( option.items ) {
            setOptSelected( optSelected.find( o => o === index) ? optSelected.filter( o => o !== index ) : [ ...optSelected, index ])
        } else if( option.component ) {
            onClickItem(option)
            appContext.setMenuOptSelected(option.title);
        }
    }

    const renderMenu = () => getOptions(appContext).map((o, index) => (
        <div className={ styles.accordion } key={ index }>
             <button key={ index }
                className={ `${styles.option} ${ o.component ? styles.option_item : '' } ${ appContext.menuOptSelected === o.title ? styles.selected : '' }` }
                onClick={ () => onClickOption(index+1, o) }>
                <div className={ `${styles.option_container} ${optSelected.find( oS => oS === index+1) ? styles.opened : ''}` }>
                    <div className={ styles.option_container_title }>
                        { o.icon }
                        <span>{ o.title }</span>
                    </div>
                    { o.items && <ArrowIcon /> }
                </div>
            </button>
            { optSelected.find( oS => oS === index+1) && renderItems(o.items) }
        </div>
    ));

    return (
        <div className={ styles.menu }>
            { renderMenu() }
            { appContext.showMenu && 
                <div className={ styles.button_menu_show } onClick={ () => appContext.setShowMenu(!appContext.showMenu) }>
                    <div className={ `${styles.icon} ${ appContext.showMenu ? styles.svg : ''}` }>
                        <ArrowMenuIcon />
                    </div>
                </div>
            }
            { appContext.showMenu &&
                <div className={ styles.version }>
                    <span>{ `Versión ${process.env.REACT_APP_VERSION}` }</span>
                </div>
            }
        </div>
    )
}
