import { useContext, useEffect } from 'react';
import { Menu } from '../../components/menu/Menu';
import styles from './home.module.scss';
import Header from '../../components/header/header';
import {ReactComponent as ArrowMenuIcon } from '../../../assets/icons/arrow_menu.svg';
import { AppContext } from '../candidates/context/AppProvider';
import { Navbar } from '../../components/navbar/Navbar';
import CandidateInfo from '../candidates/candidateInfo';
import { getFeedback } from '../../api/feedbackClient';
import { setSelectDatesReview } from '../feedback/FeedbackList';
import { Vacancy } from '../vacancies/Vacancy';
import { CatalogContext } from '../catalogs/context';
import { defaultStringEmpty } from '../../core/talent-module/helper';
import { VacancyList } from '../vacancies/VacancyList';

export const Home = () => {

    const appContext = useContext(AppContext);
    const { setVacancy } = useContext(CatalogContext);

    const redirectToSharedLink = () => {
        const candidateData = JSON.parse(localStorage.getItem('candidateData'));
        localStorage.removeItem('candidateData');
        const feedbackData = JSON.parse(localStorage.getItem('feedbackData'));
        localStorage.removeItem('feedbackData');
        const vacancyData = JSON.parse(localStorage.getItem('vacancyData'));
        localStorage.removeItem('vacancyData');
        if (candidateData) {
            appContext.setTalent({id: candidateData.candidateId});
            appContext.contentScrollUp();
            appContext.setMenuNavigation([...appContext.menuNavigation, { label: defaultStringEmpty(candidateData.candidateName)}]);
            appContext.setHomeContent(<CandidateInfo />)
        } else if (feedbackData) {
            getFeedback(feedbackData.userId, feedbackData.talentId, feedbackData.reviewId, appContext.userData.token).then(({status, data}) => {
                if( status === 200 && data.code === 201 ) {
                    appContext.setCandidateMode(false);
                    appContext.setTalent({ id: feedbackData.talentId })
                    appContext.setMenuOptSelected(appContext.t('menu.talent.opt1'));
                    const review = { ...data.result, showReport: feedbackData.checkupId ? true : false }
                    const reWithDates = setSelectDatesReview(review);
                    reWithDates.period = `${reWithDates.reviewDay} ${appContext.t('paginator.of')} ${reWithDates.reviewMes.description} ${reWithDates.reviewAnio.description}`;
                    appContext.setFeedback(reWithDates)
                    appContext.setHomeContent(<CandidateInfo tab={8} resumeTab={1} />)
                }
            }).catch(error => {
                console.log(error);
            })
        } else if (vacancyData) {
            appContext.setMenuNavigation([
                {label: appContext.t('menu.vacancy.title'), component: <VacancyList /> },
                { label: `${vacancyData.leadId}: ${defaultStringEmpty(vacancyData.vacancyProfileName)}`}
            ]);
            appContext.setMenuOptSelected(appContext.t('menu.vacancy.title'));
            setVacancy({ id: vacancyData.vacancyId, leadId: vacancyData.leadId })
            appContext.setHomeContent(<Vacancy />)
        }
    }

    useEffect(() => {
        // For check session
        // TODO For Public and Private Router
        if (!appContext.userData || !appContext.userData.hasOwnProperty('userProfile')) {
            localStorage.removeItem('userInfo');
            appContext.setUserData(null);
            appContext.navigate("/login");
        } else {
            redirectToSharedLink();
        }

    }, [])

    const renderLoading = () => appContext.loadingData.flag && (
        <div className={ styles.loading_container }>
            <div className={ `${styles.spinner_container} ${appContext.showMenu ? styles.spinner_container_short : ''}` }>
                <div className={ styles.spinner }></div>
                <p>{ appContext.loadingData.loadingLabel }</p>
            </div>
        </div>
    )

    return (
        <div className={ styles.home_container }>

            { appContext.modalFlag && appContext.modal }
            <Header />
            { appContext.notification }
            { appContext.showMenu && 
                <div className={ styles.menu }>
                    <Menu />
                </div>
            }
            { !appContext.showMenu && 
                <div className={ styles.button_menu_hidden } onClick={ () => appContext.setShowMenu(!appContext.showMenu) }>
                    <div className={ `${styles.icon} ${ appContext.showMenu ? styles.svg : ''}` }>
                        <ArrowMenuIcon />
                    </div>
                </div>
            }
            <div className={ `${styles.content} ${!appContext.showMenu ? styles.content_full : ''}` }>
                { renderLoading() }
                { <Navbar /> }
                <div ref={ appContext.contentRef } className={ styles.content_scroll }>{ appContext.homeContent }</div>
            </div>
        </div>
    )
}
