import { useContext, useEffect, useState } from "react";
import styles from './vacancy.module.scss';
import stylesHeader from '../../components/header/header.module.scss';
import { VacancyResume } from "./components/VacancyResume"
import { VacancyDescription, validateVacancy } from "./components/VacancyDescription";
import { VacancyResponsabilities } from "./components/VacancyResponsabilities";
import { VacancySkills } from "./components/VacancySkills";
import { VacancyLanguages } from "./components/VacancyLanguages";
import { VacancyBenefits } from "./components/VacancyBenefits";
import { defaultStringEmpty } from "../../core/talent-module/helper";
import { objEquals, serializeSelectValue } from "../../core/helper";
import closeIcon from '../../../assets/icons/close.svg';
import backArrowIcon from '../../../assets/icons/back-arrow.svg';
import { getAddressesCatalog, getCatalog, getLangLevelCatalog } from "../../api/catalogClient";
import { LanguagesLevel } from "../candidates/components/languages/level_description";
import { getOpenPosition } from "../../api/vacancyClient";
import { sorterAlphAsc } from "../catalogs/profiles/ProfileList";
import { AppContext } from "../candidates/context/AppProvider";
import { CatalogContext } from "../catalogs/context";
import { getAllUsers } from "../../api/userClient";

export const Vacancy = ({
    stepIn = 0
}) => {
    const appContext = useContext(AppContext);
    const {
        vacancy,
        vacancyCache,
        setVacancy,
        setVacancyCache
    } = useContext(CatalogContext);

    const [step, setStep] = useState(stepIn);
    const [vacancyErrors, setVacancyErrors] = useState(false);
    const [catStatus, setCatStatus] = useState([]);
    const [catArea, setCatArea] = useState([]);
    const [catRecruiter, setCatRecruiter] = useState([]);
    const [catAddress, setCatAddress] = useState([]);
    const [catLanguages, setCatLanguages] = useState([]);
    const [catLevels, setCatLevels] = useState();
    const [catBenefits, setCatBenefits] = useState([]);
    const [areaSelecteds, setAreaSelecteds] = useState([]);
    const [recruiterSelecteds, setRecruiterSelecteds] = useState([]);
    const [languagesSelecteds, setLanguagesSelecteds] = useState([]);

    const loadCatStatus = ({status, data}) => {
        const statusTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({catalogItemId, valueItem}) => statusTmp.push({ id: catalogItemId, optionName: valueItem }))
            setCatStatus(statusTmp);
        }
        return statusTmp;
    }

    const loadCatTranslate = ({status, data}) => {
        const languagesTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({description, valueItem}) => languagesTmp.push({ id: Number(valueItem), optionName: description }))
            setCatLanguages(languagesTmp);
        }
        return languagesTmp;
    }

    const loadCatLangLevels = ({status, data}) => {
        const langLevelsTmp = [];
        if( status === 200 && data && data.code === 202 && data.result && Array.isArray(data.result) ) {
            appContext.showLoading(false, '', styles.no_scroll);
            data.result.map( l => {
                let desc = l.description ? l.description : '';
                desc = desc.substring(0, desc.indexOf(':'));
                desc = `${l.name}${desc ? ' - ' : ''}${desc}`;
                const language = { id: l.id, optionName: desc}
                langLevelsTmp.push(language);
            })
            setCatLevels(langLevelsTmp);
        }
        return langLevelsTmp;
    }

    const loadCatBenefits = ({status, data}) => {
        const benefitsTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({catalogItemId, valueItem}) => benefitsTmp.push({ id: catalogItemId, optionName: valueItem }))
            setCatBenefits(benefitsTmp);
        }
        return benefitsTmp;
    }

    const loadCatArea = ({status, data}) => {
        const catTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({catalogItemId, valueItem}) => catTmp.push({ id: catalogItemId, optionName: valueItem }))
            setCatArea(catTmp);
        }
        return catTmp;
    }

    const loadCatAddresses = ({status, data}) => {
        const catTmp = [];
        if( status === 202 && data && data.code === 202 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({catalogItemId, valueItem}) => catTmp.push({ id: catalogItemId, optionName: valueItem }))
            setCatAddress(catTmp);
        }
        return catTmp;
    }

    const loadRecruiters = ({status, data}) => {
        const recruitersTmp = [];
        if( status === 200 && data && data.code === 200 && data.result && data.result.content && Array.isArray(data.result.content) ) {
            data.result.content.map(({id, firstName, lastName}) => recruitersTmp.push({ id: id, optionName: `${firstName} ${lastName}` }))
            setCatRecruiter(recruitersTmp);
        }
        return recruitersTmp;
    }

    const loadLanguages = (languages, catLanguages, catLevels) => {
        const languagesTmp = [];
        const languagesSelected = [];
        languages.forEach((language, index) => {
            const element = {
                ...language,
                id: index+1,
                index,
                langLevelId: language.langLevelId.id,
                langLevelName: language.langLevelId.name,
                langLevelDescription: language.langLevelId.description,
                languageId: language.languageId.id,
                languageName: language.languageId.name,
                languageDescription: language.languageId.description,
            };
            element.index = index;
            const lang = language.languageId ? catLanguages.find( l => l.id === language.languageId.id ) : undefined
            const level = language.langLevelId ? catLevels.find( l => l.id === language.langLevelId.id) : undefined;
            element.language = serializeSelectValue(lang ? lang : { id: language.languageId.id, optionName: language.languageId.name } );
            element.langLevel = serializeSelectValue(level ? level : { id: language.langLevelId.id, optionName: language.langLevelId.name });
            languagesTmp.push(element);
            languagesSelected.push(language.languageId.id);
        })
        setLanguagesSelecteds(languagesSelected)
        return languagesTmp;
    }

    const loadBenefits = (benefits) => {
        let benefitsTmp = [];
        if (benefits) {
            benefits.map((item, index) => {
                let itemWithTagValue = { ...item, id: item.catalogItemId, tagId: item.catalogItemId, index };
                itemWithTagValue.tag = {id: item.catalogItemId, description: item.valueItem};
                benefitsTmp.push(itemWithTagValue);
            })
            benefitsTmp = benefitsTmp.sort((a, b) => sorterAlphAsc(a,b));
        }
        return benefitsTmp;
    }

    const listWorkModality = vacancy => ([
        { label: appContext.t('vacancy.info.modality.address'), value: defaultStringEmpty(vacancy.address) },
        { label: appContext.t('vacancy.info.modality.horary'), value: defaultStringEmpty(vacancy.workingHours) },
        { label: appContext.t('vacancy.info.modality.detail'), value: defaultStringEmpty(vacancy.modality) },
    ])

    const listHistory = vacancy => [
        { label: appContext.t('vacancy.info.history.experience'), value: defaultStringEmpty(vacancy.requiredExperience) },
        { label: appContext.t('vacancy.info.history.education'), value: defaultStringEmpty(vacancy.education) }
    ]

    const loadVacancy = catalogs => {
        // getVacancy(appContext.userData.token, vacancy.id).then(({status, data}) => {
        getOpenPosition(appContext.userData.token, vacancy.id).then(({status, data}) => {
            // console.log('response', data.result);
            if( status === 200 && data.code === 202 && data.result ) {
                const { result } = data;
                const vacancyTmp = { ...result, leadId: vacancy.leadId };
                vacancyTmp.status = { id: result.status.catalogId, optionName: result.status.valueItem };
                vacancyTmp.areaName = result.area ? result.area.valueItem : '';
                vacancyTmp.area = result.area ? { id: result.area.catalogItemId, description: result.area.valueItem } : undefined;
                vacancyTmp.recruiterName = defaultStringEmpty(result.recruiter ? `${result.recruiter.firstName} ${result.recruiter.lastName}` : '');
                vacancyTmp.recruiter = result.recruiter ? { id: result.recruiter.id, description: `${result.recruiter.firstName} ${result.recruiter.lastName}` } : undefined;
                vacancyTmp.country = result.country ? { id: result.country.catalogItemId, description: result.country.valueItem} : { id: 0, description: result?.remoteJobCountries};
                vacancyTmp.city = '';
                vacancyTmp.modalities = listWorkModality(result);
                vacancyTmp.history = listHistory(result);
                vacancyTmp.skills = result.skills;
                vacancyTmp.languages = loadLanguages(result.languages, catalogs[1], catalogs[2]);
                vacancyTmp.benefits = loadBenefits(result.benefits);
                setVacancy(vacancyTmp);
                setVacancyCache(vacancyTmp);
            }
            appContext.showLoading(false, '', styles.no_scroll);
        }).catch(error => {
            appContext.showLoading(false, '', styles.no_scroll);
            console.log('Error loading vacancy', error);
        })
    }

    const loadCatalogs = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        const requests = [
            getCatalog(1000020002, 0 ,200, '&sort=id&direction=ASC', appContext.userData?.token),
            getCatalog(1000020054, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token),
            getLangLevelCatalog(appContext.userData.token),
            getCatalog(1000020031, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token),
            getCatalog(1000020018, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token),
            getAllUsers(appContext.userData.token, null, 1000000004, null, false),
            getCatalog(1000020016, 0 ,200, '&sort=valueItem&direction=ASC', appContext.userData.token),
        ]
        const functions = [
            loadCatStatus,
            loadCatTranslate,
            loadCatLangLevels,
            loadCatBenefits,
            loadCatArea,
            loadRecruiters,
            loadCatAddresses
        ]
        const catalogs = [];
        Promise.all(requests).then( responses => {
            responses.forEach((response, index) => catalogs.push(functions[index](response)))
            if(catalogs.length === functions.length) {
                loadVacancy(catalogs);
            }
        }).catch( error => {
            console.log('Error loading catalogs', error);
            appContext.showLoading(false, '', styles.no_scroll);
            loadVacancy(catalogs);
        });
    }

    useEffect(() => {
        loadCatalogs();
    }, [])

    const updateView = (index, navLabel) => {
            let menuNavigation = [ ...appContext.menuNavigation ];
            menuNavigation.pop();
            if( !index ) {
                appContext.setMenuNavigation(menuNavigation);
            } else {
                menuNavigation.push({
                    label: defaultStringEmpty(`${vacancy.leadId}: ${defaultStringEmpty(vacancy.profileName)}`),
                    onEvent: setStep,
                    arg: '0'
                });
                menuNavigation.push({
                    label: navLabel,
                    onEvent: setStep,
                    arg: index
                });
                appContext.setMenuNavigation(menuNavigation);
            }
            appContext.contentScrollUp();
            setStep(index);
        }

    const onChangeInput = (property, value) => {
        setVacancy({ ...vacancy, [property]: value});
    }

    const onChangeSelect = (index, property, value, preValue) => {
        if (property === 'area' && value && value.id && value.id !== 0) {
            const optSelectedTmp = areaSelecteds.filter( o => o !== preValue.id );
            setAreaSelecteds([...optSelectedTmp, value.id])
        } else if (property === 'recruiter' && value && value.id && value.id !== 0) {
            const optSelectedTmp = recruiterSelecteds.filter( o => o !== preValue.id );
            setRecruiterSelecteds([...optSelectedTmp, value.id])
        }
        setVacancy({
            ...vacancy,
            [property]: value && value.id ? value : { id: 0, description: value.description },
            [`${property}Id`]: value && value.id ? value.id : 0,
            [`${property}Value`]: value && value.description ? value.description : ''
        });
    }

    const removedFromIcon = (id, property) => {
        if (property === 'area') {
            const indexHelper = areaSelecteds.indexOf(id)
            setAreaSelecteds([...areaSelecteds.slice(0, indexHelper), ...areaSelecteds.slice(indexHelper + 1)])
        } else if (property === 'recruiter') {
            const indexHelper = recruiterSelecteds.indexOf(id)
            setRecruiterSelecteds([...recruiterSelecteds.slice(0, indexHelper), ...recruiterSelecteds.slice(indexHelper + 1)])
        }
    }

    const renderBackButton = (title, stepValue) => step === 6 && (
        <div className={ styles.backbutton } onClick = { () => setStep(4) }>
            <img src={ backArrowIcon } alt="" /><span>{ appContext.t('button.back') }</span>
        </div>
    )

    const formSections = [
        <VacancyResume
            appContext={appContext}
            updateView={ updateView }
            vacancy={vacancy}
            catStatus={catStatus}
        />,
        <VacancyDescription
            appContext={appContext}
            vacancy={vacancy}
            vacancyErrors={vacancyErrors}
            areaSelecteds={areaSelecteds}
            setAreaSelecteds={setAreaSelecteds}
            recruiterSelecteds={recruiterSelecteds}
            setRecruiterSelecteds={setRecruiterSelecteds}
            removedFromIcon={removedFromIcon}
            catArea={catArea}
            catRecruiter={catRecruiter}
            catAddress={catAddress}
            onChangeInput={onChangeInput}
            onChangeSelect={onChangeSelect}
        />,
        <VacancyResponsabilities
            appContext={appContext}
            vacancy={vacancy}
            vacancyErrors={vacancyErrors}
            onChangeInput={onChangeInput}
        />,
        <VacancySkills
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
        />,
        <VacancyLanguages
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyCache={vacancyCache}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
            languagesSelecteds={languagesSelecteds}
            setLanguagesSelecteds={setLanguagesSelecteds}
            catLanguages={catLanguages}
            catLevels={catLevels}
            setStep={setStep}
        />,
        <VacancyBenefits
            appContext={appContext}
            vacancy={vacancy}
            setVacancy={setVacancy}
            vacancyErrors={vacancyErrors}
            setVacancyErrors={setVacancyErrors}
            catBenefits={catBenefits}
        />,
        <LanguagesLevel classNameTitle={ `${styles.purple_title} ${styles.purple_title_28px}` } renderBackButton={renderBackButton} />,
    ]

    const onModalDiscard = () => {
        setVacancy(vacancyCache)
        onCancel();
    }

    const modalChangesBase = (title, child) => (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ title }</p>
                    <img src={ closeIcon } alt='' onClick={ () => appContext.setModalFlag(false) } />
                </div>
                { child }
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={onModalDiscard}>{ appContext.t('checkUp.modalCancel.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={() => onSave()}>{ appContext.t('button.save') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const renderModalChanges = () => modalChangesBase(appContext.t('candidate.modal.change.title'),
        <span><b>{ appContext.t('candidate.modal.change.msg1') } { appContext.t('vacancy.modal.change.msg') }.</b> { appContext.t('candidate.modal.change.msg2') }</span>
    )

    const onCancel = () => {
        appContext.setModalFlag(false);
        appContext.contentScrollUp();
        updateView(0, `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`);
    }

    const saveSuccess = () => {
        appContext.displayNotification((appContext.t('update')));
        updateView(0, `${vacancy.leadId}: ${defaultStringEmpty(vacancy.vacancyProfileName)}`);
    }

    const onSave = () => {
        appContext.setModalFlag(false);
        setVacancyErrors({});
        let validationErrors = {}
        if ( step === 1 ) {
            validationErrors = validateVacancy(vacancy);
            if (Object.keys(validationErrors).length === 0) {
                setVacancyCache(vacancy);
                saveSuccess();
            } else {
                setVacancyErrors(validationErrors);
            }
        }
    }

    const onValidateBack = () => {
        if(!objEquals(vacancy, vacancyCache)) {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(renderModalChanges());
        } else {
            onCancel()
        }
    }

    const renderButtons = () => (
        <div className={ `${styles.buttons_container} ${styles.paddingEnd}` }>
            <div className={ styles.buttons }>
                <button className={ `${styles.button} ${styles.button_cancel}` } onClick={ onValidateBack }>{ appContext.t('button.cancel') }</button>
                <button className={ `${styles.button} ${styles.button_save}` } onClick={() => onSave()}>{ appContext.t('button.save') }</button>
            </div>
        </div>
    )

    return (
        <div className={ styles.vacancy}>
            { appContext.isDesktopOrLaptop && renderBackButton( null, step === 6 ? 4 : null) }
            { formSections[step] }
            { step > 0 && <hr className={ styles.margin_long } /> }
            { step > 0 && step !== 6 && renderButtons() }
        </div>
    );
}
