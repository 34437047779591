import styles from './../vacancy.module.scss';
import editIcon from '../../../../assets/icons/orange_pencil.svg';
import locationIcon from '../../../../assets/icons/location.svg';
import areaIcon from '../../../../assets/icons/area.svg';
import personIcon from '../../../../assets/icons/person.svg';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { Select } from '../../../components/talent-module/searcher/Select';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId } from '../../../core/talent-module/hardcode';

export const VacancyResume = ({
    appContext,
    updateView,
    vacancy,
    catStatus
}) => {

    const renderHeader = (title, step) => (
        <div className={ styles.header }>
            <div className={ styles.container }>
                <div className={ styles.orange_bar } />
                <span className={ `${styles.purple_title} ${styles.purple_title_20px}` }>{ title }</span>
            </div>
            <div className={ styles.thin_bar } />
            <div className={ styles.container }>
                <button className={ styles.add_info } onClick={() => updateView(step, title)}>
                    { appContext.t('candidate.resume.edit') }
                    <img src={ editIcon } alt="" />
                </button>
            </div>
        </div>
    )

    const renderSpanRow = (label, value, isFiftyPerc) => (
        <div className={ styles.span_row }>
            <span className={styles.black_label}>{label}:</span>
            <span className={isFiftyPerc ? styles.span_50 : undefined}>{value}</span>
        </div>
    )

    const renderVacancyDetail = (icon, label, value) => (
        <div className={ styles.detail_info }>
            <img src={ icon } width={24} height={24} alt="" />
            { renderSpanRow(appContext.t(label), value) }
        </div>
    )

    const renderVacancyTitle = () => {
        // Dependiendo de la modalidad de trabajo
        // vacancy.country
        // vacancy.jobLocation
        const noVisible = vacancy.visibility && vacancy.visibility.includes('No');
        return (
            <div className={ styles.title }>
                <span className={ styles.title_label }>{ defaultStringEmpty(vacancy.profileName) }</span>
                <div className={ styles.detail }>
                    <span className={`${styles.fontwhite} ${styles.border} ${noVisible ? styles.border_gray : styles.border_green}`}>{ vacancy.visibility }</span>
                    <div className={ styles.thin_bar } />
                    { renderVacancyDetail(locationIcon, 'candidate.resume.info.location', `${defaultStringEmpty(vacancy.city)}, ${defaultStringEmpty(vacancy.country?.description)}`) }
                    <div className={ styles.thin_bar } />
                    { renderVacancyDetail(areaIcon, 'candidate.resume.assignment.labelArea', defaultStringEmpty(vacancy.areaName)) }
                    <div className={ styles.thin_bar } />
                    { renderVacancyDetail(personIcon, 'candidate.resume.postulation.recruiter', vacancy.recruiterName) }
                </div>
            </div>
        )
    }

    const renderSection = (child, subtitle) => (
        <div className={ styles.section }>
            { subtitle && <span className={ styles.purple_title }>{ appContext.t(subtitle) }</span> }
            { child }
        </div>
    )

    const renderList = (list, isFiftyPerc) => list && list.length > 0 ? <ul>{ list.map((item, index) => (
        <li key={index}>{ typeof item === 'string' ? item : renderSpanRow(item.label, item.value, isFiftyPerc) }</li>
    ))}</ul> : appContext.t('noData.unspec')

    const renderCatList = list => list && renderList(list.map(({tag}) => tag.description));

    const renderLanguages = languages => languages && renderList(languages.map((language) => ({
        label: `${language.languageName} ${language.langLevelName} - ${ language.langLevelDescription ? language.langLevelDescription.split(":")[0] : appContext.t('noData.unspec') }`,
        value: language.langLevelDescription ? language.langLevelDescription.split(":")[1] : ''
    })), true);

    const renderStatus = () => (
        <div className={ styles.header }>
            <span className={ styles.black_label }>{ appContext.t('candidate.list.headers.status') }:</span>
            <Select
                name='status'
                value={ vacancy.status }
                setValue={ () => console.log('set Value') }
                options = { catStatus }
                placeholder = { '' }
                noSearcheable
                onChange={ () => console.log('Update Status') }
                optRigth
                fontbold
            />
        </div>
    )

    return (
        <div className={ `${styles.main} ${styles.paddingEnd}` }>
            <div className={ styles.card }>
                <div className={ styles.title_container }>
                    { renderHeader(appContext.t('vacancy.info.title'), 1) }
                    { renderStatus() }
                </div>
                { renderVacancyTitle() }
                { renderSection(renderList(vacancy.modalities), 'vacancy.info.modality.title') }
                { renderSection(renderList(vacancy.history), 'vacancy.info.history.title') }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('vacancy.responsabilities.title'), 2) }
                { renderSection(renderList( vacancy.description ? [vacancy.description] : [])) }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('candidate.resume.skills.title'), 3) }
                { renderSection(renderList(vacancy.skills?.filter(skill => skill.categoryId === hardSkillsCatalogId).map(({skillName}) => skillName)), 'candidate.resume.skills.hard') }
                { renderSection(renderList(vacancy.skills?.filter(skill => skill.categoryId === softSkillsCatalogId).map(({skillName}) => skillName)), 'candidate.resume.skills.soft') }
                { renderSection(renderList(vacancy.skills?.filter(skill => skill.categoryId === softwareCatalogId).map(({skillName}) => skillName)), 'candidate.resume.skills.work') }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('candidate.resume.lang.title'), 4) }
                { renderSection(renderLanguages(vacancy?.languages)) }
            </div>
            <div className={ styles.card }>
                { renderHeader(appContext.t('vacancy.benefits.title'), 5) }
                { renderSection(renderCatList(vacancy.benefits)) }
            </div>
        </div>
    )
}
