import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../candidates/context/AppProvider";
import { CandidateContext } from "../../../candidates/context";
import styles from '../../../candidates/candidateInfo.module.scss';
import stylesHeader from '../../../../components/header/header.module.scss';
import stylesM from '../../../../components/modal/modal_base.module.scss';
import { ProfileList } from "../ProfileList";
import { InputText } from "../../../../components/inputs/InputText";
import Select, { SelectSortType } from "../../../../components/inputs/Select";
import { Checkbox } from "../../../../components/inputs/Checkbox";
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId, workSkillsCatalogId } from "../../../../core/talent-module/hardcode";
import addIcon from '../../../../../assets/icons/add_circle.svg';
import closeIcon from '../../../../../assets/icons/close.svg';
import trashIcon from '../../../../../assets/icons/trash.svg';
import editIcon from '../../../../../assets/icons/blue_pencil.svg';
import { validateNotNull } from "../../../../core/validators";
import { objEquals } from "../../../../core/helper";
import { getClientCatalog, persistProfile } from "../../../../api/catalogClient";
import CandidateInfo, { LoadedFrom } from "../../../candidates/candidateInfo";
import { CatalogContext } from "../../context";
import { InputSearcher } from "../../../candidates/components/skills/InputSearcher";
import { newSkill, selectEmtpy } from "../../../../core/hardcode";
import { checkSkillWithCompetition, getOptionsSelecteds } from "../../../candidates/components/skills/skills";

export const ProfileDetail = ({
    catSkills
}) => {

    const appContext = useContext(AppContext);
    const {
        competition,
        setCompetition,
        setCompetitionErrors
    } = useContext(CandidateContext);
    const {
        profile,
        profileCache,
        setProfile,
        cleanProfile
    } = useContext(CatalogContext);
    const { skill, cleanSkill } = useContext( CandidateContext );
    const [optionsSelecteds, setOptionsSelecteds] = useState([]);
    const [profileErrors, setProfileErrors] = useState({});
    const [skillErrors, setSkillErrors] = useState({});
    const [catClient, setCatClient] = useState([]);

    const validationFields = {
        name: [validateNotNull]
    }

    const validateNewProfile = () => {
        let firstFocus = true
        const errorObject = {};
        var helper;
        for(const field in validationFields) {
            for (const validator in validationFields[field]) {
                helper = validationFields[field][validator](profile[field])
                if (helper) {
                    errorObject[field] = helper
                    if( firstFocus ) {
                        document.getElementsByName(field)[0].focus();
                        firstFocus = false;
                    }
                }
            }
        }
        const skillErrorObject = validateSkillRequest(firstFocus);
        return {
            ...errorObject,
            ...skillErrorObject
        };
    }

    const validateSkills = (firstFocus, skillsCacheFiltred) => {
        const errorDictHelper = {}
        for (const skillObjectIndex in skillsCacheFiltred) {
            const skill = skillsCacheFiltred[skillObjectIndex];
            if (skill.id) {
                continue
            }
            const id = `${skillsCacheFiltred[skillObjectIndex].categoryId}_${skillObjectIndex}`;
            errorDictHelper[id] = appContext.t('validator.required');
            if( firstFocus ) {
                document.getElementById(id).focus();
                firstFocus = false;
            }
        }
        return {
            focus: firstFocus,
            errors: errorDictHelper
        };
    }

    const validateSkillRequest = (firstFocus, categoryId = null) => {
        let errorDictHelper = {}
        if ( categoryId ) {
            const skillsCacheFiltred = profile.skills ? profile.skills.filter(skill => skill.categoryId === categoryId) : [];
            errorDictHelper = validateSkills(firstFocus, skillsCacheFiltred).errors;
        } else {
            const skillCatalogIds = [hardSkillsCatalogId,softSkillsCatalogId,softwareCatalogId,workSkillsCatalogId];
            let focus = firstFocus;
            skillCatalogIds.forEach( cs => {
                const skillsCacheFiltred = profile.skills ? profile.skills.filter(skill => skill.categoryId === cs) : [];
                const result = validateSkills(focus, skillsCacheFiltred);
                focus = result.focus;
                errorDictHelper = { ...errorDictHelper, ...result.errors };
            })
        }
        return errorDictHelper;
    }

    const loadCatClient = ({status, data}) => {
        if( status === 200 && data && data.code === 200 && data.result && Array.isArray(data.result) ) {
            let clientsTmp = [];
            data.result.map(l => clientsTmp.push({ id: l.id, optionName: l.name}))
            setCatClient(clientsTmp);
        }
    }

    const checkSkillFromCompModule = () => {
        if( competition ) {
            const index = profile.skills.findIndex(s => checkSkillWithCompetition(s, competition));
            if( index >= 0 ) {
                let skillsTmp = [ ...profile.skills ];
                skillsTmp[index].id = competition.tagId;
                skillsTmp[index].tagId = competition.tagId;
                skillsTmp[index].name = competition.tag.description;
                skillsTmp[index].tag = competition.tag;
                skillsTmp[index].categoryId = competition.categoryId;
                setProfile({ ...profile, skills: skillsTmp });
                setCompetition(null);
            }
        }
        appContext.showLoading(false, '', styles.no_scroll);
    }

    const loadCatalogs = () => {
        appContext.showLoading(true, appContext.t('loading'), styles.no_scroll);
        const requests = [
            getClientCatalog(appContext.userData.token)
        ]
        const functions = [
            loadCatClient
        ]
        Promise.all(requests).then( responses => {
            const catalogs = [];
            responses.forEach((response, index) => catalogs.push(functions[index](response)))
            if(catalogs.length === functions.length) {
                checkSkillFromCompModule();
            }
        }).catch( error => {
            console.log('Error loading catalogs', error);
            appContext.showLoading(false, '', styles.no_scroll);
        });
    }

    useEffect(() => {
        loadCatalogs();
    }, [])

    const updateProfile = (property, value) => {
        setProfile({ ...profile, [property]: value});
    }

    const updateClient = (index, property, value, preValue) => {
        setProfile({
            ...profile,
            [property]: value && value.id ? value : { id: 0, description: value.description },
            [`${property}Id`]: value.id ? value.id : 0
        });
        if (value && value.id && value.id !== 0) {
            const optSelectedTmp = optionsSelecteds.filter( o => o !== preValue.id );
            setOptionsSelecteds([...optSelectedTmp, value.id])
        }
    }

    const removedFromIcon = (id) => {
        const indexHelper = optionsSelecteds.indexOf(id)
        setOptionsSelecteds([...optionsSelecteds.slice(0, indexHelper), ...optionsSelecteds.slice(indexHelper + 1)])
    }

    const onClickAdd = categoryId => {
        // se valida
        const validationHelper = validateSkillRequest(true, categoryId);
        if (Object.keys(validationHelper).length === 0) {
            const skillNew = { ...skill, 'categoryId': categoryId };
            cleanSkill(categoryId);
            const skillsCacheTmp = profile && profile.skills ? [ ...profile.skills ] : [];
            setProfile({ ...profile, skills: [ ...skillsCacheTmp, skillNew] });
        }
        setSkillErrors(validationHelper);
    }

    const saveProfile = () => {
        const skilltags = [];
        if( profile.skills ) {
            profile.skills.forEach( s => skilltags.push(s.id) );
        }
        const profileRequest = {
            id: profile.id,
            name: profile.name,
            isPublic: profile.isPublic,
            clientId: profile.clientId ? profile.clientId : null,
            skilltagIds: skilltags
        }
        appContext.showLoading(true, appContext.t('saving'), styles.no_scroll);
        persistProfile(profileRequest, appContext.userData.token).then( response => {
            const msgSuccess = profile && profile.id ? appContext.t('update') : `${appContext.t('catalogs.profile.title')} ${appContext.t('created')}`;
            appContext.showLoading(false, '', styles.no_scroll);
            appContext.displayNotification((msgSuccess));
            gotoNext();
        }).catch( error => {
            console.log('Error al ejecutar el servicio', error);
            appContext.showLoading(false, '', styles.no_scroll);
        })
    }

    const onSave = () => {
        const profileErrors = validateNewProfile();
        const hasErrors = Object.keys(profileErrors).length !== 0;
        if (!hasErrors) {
            saveProfile();
        } else {
            setProfileErrors(profileErrors);
            setSkillErrors(profileErrors);
        }
    }

    const onModalConfirm = () => {
        appContext.setModalFlag(false);
        onSave();
    }

    const onModalDiscard = () => {
        appContext.setModalFlag(false);
        gotoNext();
    }

    const renderModalChanges = () => (
        <div className={ stylesHeader.error_modal }>
            <div className={ stylesHeader.wrapper }>
                <div className={ stylesHeader.content_title }>
                    <p className={ stylesHeader.title }>{ appContext.t('candidate.modal.change.title') }</p>
                    <img src={ closeIcon } alt='' onClick={ () => appContext.setModalFlag(false) } />
                </div>
                <div className={ stylesHeader.content_buttons }>
                    <div className={ stylesHeader.buttons }>
                        <button className={ stylesHeader.cancel } onClick={ () => onModalDiscard() }>{ appContext.t('candidate.modal.change.cancel') }</button>
                        <button className={ stylesHeader.confirm } onClick={ () => onModalConfirm() }>{ appContext.t('candidate.modal.change.save') }</button>
                    </div>
                </div>
            </div>
        </div>
    )

    const onCancel = () => {
        if(!objEquals(profileCache, profile)) {
            appContext.setModalFlag(!appContext.modalFlag);
            appContext.setModalContent(renderModalChanges());
        } else {
            gotoNext();
        }
    }

    const gotoNext = () => {
        cleanProfile();
        appContext.setHomeContent(<ProfileList />)
        appContext.contentScrollUp();
        let menuNavigation = [ ...appContext.menuNavigation ];
        menuNavigation.pop();
        appContext.setMenuNavigation(menuNavigation);
    }

    const renderForm = () => (
        <div className={ styles.form_wrapper }>
            <div className={ styles.form_columns } style={{ justifyContent: 'unset' }}>
                <InputText
                    cssContainer={ styles.form_column }
                    name="name"
                    label={ `${appContext.t('catalogs.profile.labelName')}*` }
                    value={ profile.name }
                    onChange={ updateProfile }
                    error={ Object.keys(profileErrors).includes("name") ? profileErrors.name[0] : '' }
                />
                <div className={ styles.form_column } style={{ marginLeft: '40px' }}>
                    <div className={ stylesM.custom_select }>
                        <label>{ `${appContext.t('candidate.list.headers.client')}` }</label>
                        <Select
                            name="client"
                            index={1}
                            placeholder={ appContext.t('catalogs.profile.placeholderCustomer') }
                            value={ profile.client }
                            sercheable
                            onChange={ updateClient }
                            options={ catClient ? catClient : [] }
                            optionsSelecteds={ optionsSelecteds }
                            removedFromIcon={ removedFromIcon }
                            sort={ SelectSortType.ALPHA }
                        />
                        { Object.keys(profileErrors).includes("name") && '\u00A0' }
                    </div>
                </div>
            </div>
        </div>
    )

    const updateSkill = (tagId, categoryId, value, isSelected) => {
        let skillsCacheTmp = [ ...profile.skills ];
        const index = profile.skills.findIndex( s => s.tagId === tagId && s.categoryId === categoryId );
        const skill = {
            ...skillsCacheTmp[index],
            tag: value,
            tagId: isSelected ? value.id : tagId,
            id: value.id,
            name: value.description
        }
        skillsCacheTmp[index] = skill;
        setProfile({ ...profile, skills: skillsCacheTmp});
    }

    const onSkillEditPage = (value, categoryId) => {
        appContext.setTalent(null);
        appContext.contentScrollUp();
        const competition = {
            ...newSkill,
            id: value.id,
            name: value.description,
            category: { ...selectEmtpy, id: categoryId },
            categoryId
        };
        const title1 = appContext.t(`candidate.resume.skills.addForm.title${ competition.id ? 'Edit' : 'Create' }`);
        const title2 = categoryId !== softwareCatalogId ? appContext.t('candidate.resume.skills.addForm.titleSkill') : appContext.t('candidate.resume.soft.title');
        appContext.setMenuNavigation([...appContext.menuNavigation, { label: `${title1} ${title2}` }]);
        setCompetition(competition);
        setCompetitionErrors({});
        appContext.setHomeContent(<CandidateInfo tab={ 18 } rvf from={ LoadedFrom.PROFILES } />)
    }

    const handleOnClickRemove = skill => {
        let skillsCacheTmp = [ ...profile.skills ];
        skillsCacheTmp = profile.skills.filter( s => !objEquals(s.tag, skill.tag));
        setProfile({ ...profile, skills: skillsCacheTmp});
    }

    const renderDeleter = (skill, categoryId, index) => (
        <div className={ styles.action_container }>
            { skill.id ? <img src={ editIcon } alt="" onClick={ () => onSkillEditPage(skill.tag, categoryId) } /> : undefined }
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(skill, index) } />
        </div>
    )

    const renderSkills = (categoryId, skills) => skills && skills.length > 0 && (
        <div className={ `${styles.form_row} ${styles.no_gap}` }>
        {
            skills.map((skill, index) => (
                <div key={ `${categoryId}_${index}` }>
                    <div className={ `${styles.form_row} ${styles.padded_short}` }>
                        <div className={ styles.flex_14gap }>
                            <InputSearcher
                                id = {`${categoryId}_${index}`}
                                tagId = { skill.tagId }
                                value = { skill.tag }
                                placeholder = { appContext.t('candidate.resume.skills.labelSearch') }
                                onChange = { updateSkill }
                                options = { catSkills.filter( skill => skill.categoryId === categoryId ) }
                                optionsSelecteds = { getOptionsSelecteds(skills) }
                                error = { Object.keys(skillErrors).includes(`${categoryId}_${index}`) ? skillErrors[`${categoryId}_${index}`] : undefined }
                                index={ index }
                                categoryId = { categoryId }
                                onSkillEditPage = { onSkillEditPage }
                                width={ 45 }
                            />
                            { renderDeleter(skill, categoryId, index) }
                        </div>
                    </div>
                    <div className={ styles.form_row }>
                        {Object.keys(skillErrors).includes(`${categoryId}_${index}`) && (
                            <p className={ styles.error_message } style={{ width: '50%' }}>{ skillErrors[`${categoryId}_${index}`] }</p>
                        )}
                    </div>
                </div>
            ))
        }
        </div>
    );

    const renderComponentSkill = (categoryId, title) => {
        const skills = profile && profile.skills ? profile.skills.filter( skill => skill.categoryId === categoryId ) : [];
        return (
            <div className={ styles.form_wrapper }>
                <p className={ styles.form_section_title_2 }>{ title }</p>
                { renderSkills(categoryId, skills) }
                <div className={ `${styles.form_row} ${ skills.length === 0 ? styles.top_padded : styles.top_padded_short}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd(categoryId) }>
                        { `${appContext.t('button.add')} ${title}` }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const renderSkillsSection = () => (
        <div className={ styles.form_column_simple }>
            <label className={ styles.subtitle }>{ appContext.t('menu.catalog.opt1') }</label>
            <p>{ appContext.t('catalogs.profile.skillInfo') }</p>
            { renderComponentSkill(hardSkillsCatalogId, appContext.t('candidate.resume.skills.hard2')) }
            { renderComponentSkill(softSkillsCatalogId, appContext.t('candidate.resume.skills.soft2')) }
            { renderComponentSkill(softwareCatalogId, appContext.t('candidate.resume.soft.title')) }
            { renderComponentSkill(workSkillsCatalogId, appContext.t('candidate.resume.work.addForm.titleWork')) }
        </div>
    )

    const renderSuggestionSection = () => (
        <div className={ styles.form_column_simple }>
            <label className={ styles.subtitle }>{ appContext.t('candidate.resume.skills.addForm.labelSuggestion') }</label>
            <p>{ appContext.t('catalogs.profile.infoSuggestion') }</p>
            <Checkbox
                name="isPublic"
                label = { appContext.t('catalogs.profile.showSuggestion') }
                value={ profile.isPublic }
                onClick={ updateProfile }
            />
        </div>
    )

    const renderButtons = () => (
        <div className={ styles.buttons_spaced }>
            <div className={ styles.buttons }>
                <button className={ styles.cancel_button } onClick={() => onCancel()}>{ appContext.t('button.cancel') }</button>
                <button className={ styles.continue_button } onClick={() => onSave()}>{ profile && profile.id ? appContext.t('button.save') : appContext.t('button.create') }</button>
            </div>
        </div>
    )

    return (
        <div className={ `${appContext.showMenu ? styles.main : styles.main_full}` } >
            <div className={ styles.candidate_info } >
                <div className={ styles.wrapper }>
                    <div className={ styles.candidate_resume }>
                        <div className={ styles.content_wrapper }>
                            <div className={ styles.candidate_columns }>
                                <div className={ styles.candidate_form }>
                                    <div className={ styles.form_container }>
                                        <p className={ styles.title }>{ `${appContext.t('button.' + ( profile && profile.id ? 'edit' : 'create' ))} ${appContext.t('catalogs.profile.title')}` }</p>
                                        { renderForm() }
                                        { renderSkillsSection() }
                                        { renderSuggestionSection() }
                                    </div>
                                    <hr />
                                    { renderButtons() }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
