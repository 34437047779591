import styles from './../vacancy.module.scss';
import { TextArea } from '../../../components/inputs/TextArea';

export const VacancyResponsabilities = ({
    appContext,
    vacancy,
    vacancyErrors,
    onChangeInput
}) => {

    return (
        <div className={ styles.main }>
            <div className={ styles.card }>
                <span className={ `${styles.purple_title} ${styles.purple_title_28px}` }>{ appContext.t('vacancy.responsabilities.title') }</span>
            </div>
            <div className={ `${styles.column} ${styles.column_item} ${styles.column_item_full}` }>
                <TextArea
                    className={ styles.tall }
                    cssCounter={ styles.subtitle_left }
                    name={ 'description' }
                    label={''}
                    maxLength={ 2000 }
                    errorLen={ 'validator.length' }
                    value={ vacancy.description || "" }
                    onChange={ onChangeInput }
                    error={ Object.keys(vacancyErrors).includes("description") ? vacancyErrors.description[0] : '' }
                />
            </div>
        </div>
    )
}