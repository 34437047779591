import styles from './../vacancy.module.scss';
import addIcon from '../../../../assets/icons/add_circle.svg';
import trashIcon from '../../../../assets/icons/trash.svg';
import { defaultStringEmpty } from '../../../core/talent-module/helper';
import { hardSkillsCatalogId, softSkillsCatalogId, softwareCatalogId } from '../../../core/talent-module/hardcode';
import { objEquals } from '../../../core/helper';
import { InputText } from '../../../components/inputs/InputText';
import { validateNotNull } from '../../../core/validators';

export const VacancySkills = ({
    appContext,
    vacancy,
    setVacancy,
    vacancyErrors,
    setVacancyErrors
}) => {

    const validateSkills = skills => {
        let firstFocus = true;
        const errorDictHelper = {}
        for (const skillObjectIndex in skills) {
            const skill = skills[skillObjectIndex];
            if( validateNotNull(skill.skillName) ) {
                const name = `name_${skill.categoryId}_${skill.index}`;
                if( firstFocus ) {
                    document.getElementsByName(name)[0].focus();
                    firstFocus = false;
                }
                errorDictHelper[name] = appContext.t('validator.required');
            }
        }
        return errorDictHelper
    }

    const validateSkillRequest = categoryId => {
        const skillsCacheFiltred = vacancy.skills ? vacancy.skills.filter(skill => skill.categoryId === categoryId) : [];
        return validateSkills(skillsCacheFiltred);
    }

    const onClickAdd = categoryId => {
        // se valida
        const validationHelper = validateSkillRequest(categoryId);
        if (Object.keys(validationHelper).length === 0) {
            const skillsCacheTmp = vacancy && vacancy.skills ? [ ...vacancy.skills ] : [];
            const index = vacancy.skills.filter( skill => skill.categoryId === categoryId ).length;
            setVacancy({ ...vacancy, skills: [ ...skillsCacheTmp, { index, skillName: '', categoryId }] });
        }
        setVacancyErrors(validationHelper);
    }

    const getSkillIndex = property => {
        const propertyReal = property.split('_');
        const index = vacancy.skills.findIndex(({index, categoryId}) => {
            const currentIndex = Number(propertyReal[2]);
            const currentCategory = Number(propertyReal[1]);
            const result = ((!index && !currentIndex) || (index === currentIndex)) && categoryId === currentCategory;
            return result;
        });
        return index;
    }

    const updateSkillName = (property, value) => {
        const index = getSkillIndex(property);
        let skillsCacheTmp = [ ...vacancy.skills ];
        skillsCacheTmp[index].skillName = value;
        setVacancy({ ...vacancy, skills: skillsCacheTmp});
    }

    const handleOnClickRemove = skill => {
        let skillsCacheTmp = [ ...vacancy.skills ];
        skillsCacheTmp = vacancy.skills.filter(s => !objEquals(s, skill));
        setVacancy({ ...vacancy, skills: skillsCacheTmp});
    }

    const renderDeleter = skill => (
        <div className={ styles.column }>
            <img className={ styles.trash } src={ trashIcon } alt="" onClick={ () => handleOnClickRemove(skill) } />
            { Object.keys(vacancyErrors).includes(`name_${skill.categoryId}_${skill.index}`) && '\u00A0' }
        </div>
    )

    const renderSkills = (categoryId, skills) => skills && skills.length > 0 && (
        <div className={ styles.row }>
        {
            skills.map((skill, index) => (
                <div key={ `${categoryId}_${index}` } className={ styles.columns_line }>
                    <InputText
                        cssContainer={ `${styles.form_column} ${styles.widh80perc}` }
                        name={`name_${categoryId}_${index}`}
                        value={skill.skillName}
                        onChange={updateSkillName}
                        error={Object.keys(vacancyErrors).includes(`name_${categoryId}_${index}`) ? vacancyErrors[`name_${categoryId}_${index}`] : ''}
                    />
                    { renderDeleter(skill) }
                </div>
            ))
        }
        </div>
    );

    const renderComponentSkill = (categoryId, title) => {
        const skills = vacancy && vacancy.skills ? vacancy.skills.filter( skill => skill.categoryId === categoryId ) : [];
        return (
            <div className={ styles.wrapper }>
                <div className={ `${ skills.length > 0 ? styles.bottom_padded : undefined}` }>
                    <span>{ title }</span>
                </div>
                { renderSkills(categoryId, skills) }
                <div className={ `${styles.row} ${ skills.length === 0 ? styles.top_padded : styles.top_padded_short}` }>
                    <button className={ styles.add_info } onClick={ () => onClickAdd(categoryId) }>
                        { `${appContext.t('button.add')} ${title}` }
                        <img src={ addIcon } alt="" />
                    </button>
                </div>
            </div>
        )
    }

    const renderSkillsSection = () => (
        <div className={ styles.list }>
            { renderComponentSkill(hardSkillsCatalogId, appContext.t('candidate.resume.skills.hard2')) }
            { renderComponentSkill(softSkillsCatalogId, appContext.t('candidate.resume.skills.soft2')) }
            { renderComponentSkill(softwareCatalogId, appContext.t('candidate.resume.soft.title')) }
        </div>
    )

    return (
        <div className={ styles.main }>
            <div className={ `${styles.card} ${styles.gap40}` }>
                <span className={ `${styles.purple_title} ${styles.purple_title_28px}` }>{ appContext.t('candidate.resume.skills.title') }</span>
                <span>{ appContext.t('vacancy.skills.instruction').replace(':vacancyName', defaultStringEmpty(vacancy.vacancyProfileName)) }</span>
                { renderSkillsSection() }
            </div>
        </div>
    )
}
